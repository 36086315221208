import React from 'react';
import '../styles/about.css'

function About() {
  return (
    <div className="about-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-4 text-column">
              <p className="about-text">Maddie is from Kentucky, USA. She is currently pursuing her Master of Architecture I degree at the Harvard University Graduate School of Design.</p>
              <p className="about-text">She believes the architect's role is to craft spaces and experiences that forge meaningful relationships across built and natural systems.</p>
              <p className="about-text">By making highly particular gifts for people and places she loves, she gives attention to presence and context.</p>
              <p className="about-text">Email her at mfarrer@gsd.harvard.edu.</p>
              <p className="about-text">Check out her <a href="https://drive.google.com/file/d/1T99pyykalquLwdKH6S0UZY12CQFt2p8W/view" className="about-link" target="_blank">resume</a>.</p>
          </div>
          <div className="col-sm-4"></div>
          <div className="col-sm-4 image-column">
              <img className="about-img img-fluid rightfix" src="https://maddie-portfolio.s3.amazonaws.com/about/MF_Headshot_Color_CROPPED.jpg" alt="Your Image" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;